<template>
    <div class="container py-2">
        <div class="title bg-white px-2 py-1">
            {{ title }}
        </div>
        <div class="content bg-white p-2 my-2 radius-5">
            <div class="row">
                <label class="col-3 p-0 text-r">名称: </label>
                <div class="col">{{ datas.name }}</div>
            </div>
            <!-- <div class="row">
                <label class="col-3 p-0 text-r">人力资源: </label>
                <div class="col">小猪佩奇人力资源</div>
            </div> -->
            <div class="row">
                <label class="col-3 p-0 text-r">结算方式: </label>
                <div class="col">{{ SETTLEMENT_TYPE[datas.settlement_type] }}</div>
            </div>
            <div v-if="datas.settlement_type !==2">
                <div class="row">
                    <label class="col-3 p-0 text-r">白班工时: </label>
                    <div class="col">{{ datas.day_time/60 }}小时/天</div>
                </div>
                <div class="row">
                    <label class="col-3 p-0 text-r">夜班工时: </label>
                    <div class="col">{{ datas.night_time/60 }}小时/天</div>
                </div>
                <div class="row">
                    <label class="col-3 p-0 text-r">白班单价: </label>
                    <div class="col">{{ datas.day_price }}元/小时</div>
                </div>
                <div class="row">
                    <label class="col-3 p-0 text-r">夜班单价: </label>
                    <div class="col">{{ datas.night_price }}元/小时</div>
                </div>
                <div class="row" v-if="datas.pretest_price">
                    <label class="col-3 p-0 text-r">预发单价: </label>
                    <div class="col">{{ datas.pretest_price }}元/小时</div>
                </div>
                <div class="row" v-if="datas.pretest_time">
                    <label class="col-3 p-0 text-r">预发工时: </label>
                    <div class="col">{{ datas.pretest_time/60 }}小时/天</div>
                </div>
                <div class="row" v-if="datas.reissue_day">
                    <label class="col-3 p-0 text-r">补发日期: </label>
                    <div class="col">{{ datas.reissue_day }}日</div>
                </div>
                <div class="row">
                    <label class="col-3 p-0 text-r">结算周期: </label>
                    <div class="col">每月{{ datas.settlement_cycle[0] }}日 到 {{ datas.settlement_cycle[0] > 5 ? '次月' : "每月" }} {{ datas.settlement_cycle[1] }}日</div>
                </div>
                <div class="row">
                    <label class="col-3 p-0 text-r">到期时间: </label>
                    <div class="col">{{ datas.end_day?datas.end_day:0 }}天</div>
                </div>
            </div>
            <div v-if="datas.settlement_type == 2">
                <div class="row">
                    <label class="col-3 p-0 text-r">返 费: </label>
                    <div class="col">{{ datas.return_fee }}元</div>
                </div>
                <div class="row">
                    <label class="col-3 p-0 text-r">返费周期: </label>
                    <div class="col">{{ datas.return_day }}天</div>
                </div>
                <div class="row" v-if="datas.reissue_day">
                    <label class="col-3 p-0 text-r">支付日期: </label>
                    <div class="col">每月{{ datas.reissue_day }}日</div>
                </div>
            </div>
            
            
            <div class="row">
                <label class="col-3 p-0 text-r">几天无薪: </label>
                <div class="col">{{ datas.day_salary }}天</div>
            </div>
            
            <div class="row">
                <label class="col-3 p-0 text-r">发薪日期: </label>
                <div class="col">每月{{ datas.payday }}日</div>
            </div>
            <div class="row">
                <label class="col-3 p-0 text-r">模式说明: </label>
                <div class="col">每月{{ datas.description }}日</div>
            </div>
        </div>
        <div class="btn group row w-9">
            <a :href="`/jobs/settlement/staff/edit?id=${datas.id}`" class="btn btn-primary mx-3">编辑</a>
            <button class="btn btn-secondary mx-3">删除</button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { SETTLEMENT_TYPE } from '@/lib/data'
import { getUrlCode } from '@/lib/unitls'
export default {
    name:'staffSettlementInfo',
    setup() {
        const datas = ref({
            id: 1,
            name:"长期日结",
            jobs_id: 100,
            settlement_type: 2,
            end_day: "2022-03-30",
            day_time: 600,
            night_time: 600,
            day_price: 18.5,
            night_price: 19,// j日结夜班单价
            settlement_cycle:[10,9],
            pretest_price: 18,// 预发单价
            pretest_time: 600,// 预发工时
            reissue_day: 25,//补发日期
            return_fee: 500, //返费：同工同酬时，上满几个月给的一次性
            return_day: 60, //返费周期
            day_salary:7,
            payday: 20,  //发薪日期
            description:"模式说明模式说明模式说明模式说明模式说明模式说明"//模式说明
        })
        const title = getUrlCode('title')
        return {
            datas,
            SETTLEMENT_TYPE,
            title
        }
    }
}
</script>

<style>

</style>